// import FusePageCarded from '@fuse/core/FusePageCarded';
import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from 'app/withAppProviders';
import { useEffect } from 'react';
import ClientOrdersHead from './ClientOrdersHead';
import ClientOrdersTable from './ClientOrdersTable';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 100,
      height: 100,
    },
  },
  '& .FusePageSimple-content': {
    display: 'flex',
  },
  '& .FusePageSimple-contentCard': {
    overflow: 'hidden',
  },
  "& .ag-root-wrapper": {
    backgroundImage: 'url(../../assets/images/backgroundImage.jpeg)',
  },
}));

function ClientOrders() {
  return <Root header={<ClientOrdersHead />}
     content={<ClientOrdersTable />} innerScroll
  />;
}

export default ClientOrders
