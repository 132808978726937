import { RouteConfig } from "app/shared/types";
import FinanceCompanies from "./FinanceCompanies";

const FinanceCompaniesRouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'financeCompanies',
            element: <FinanceCompanies />,
            permissions: ["financeCompany_create", "financeCompany_read", "financeCompany_delete", "financeCompany_update"]
        },
    ],
};

export default FinanceCompaniesRouteConfig;