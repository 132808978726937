import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

function Action2Head(props) {
  const theme = useTheme();

  return (
     <div className="flex flex-1 w-full items-center justify-between">
     <div className="flex items-center">
       <Icon className="flex items-center"
           component={Link}
           role="button"
           to="/requests"
           style={{ color: 'black' }}
       >
             {theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}
         </Icon>
       <Typography
         className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
         component={motion.span}
       >
          Action 2: Finance company to platform
       </Typography>
     </div>
   </div>
  );
}

export default Action2Head;
