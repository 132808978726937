import withRouter from "@fuse/core/withRouter";
import { ColDef, GridReadyEvent} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useRef, useState, useMemo } from "react";
import { useEffect } from "react";
import { fetchClientOrders, sendButton } from "./store/ClientOrdersSlice";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { useSelector } from "react-redux";
import moment from "moment";

function ClientOrdersTable(props) {
    const dispatch = useAppDispatch()
    const originalRowData = useSelector((state: any) => state.clientOrders.items);
    const rowData = useMemo(() => {
        return originalRowData.map((item, index) => {
        return { ...item, srNumber: index + 1 };
      });
    }, [originalRowData]);    

    const gridRef = useRef<AgGridReact<any>>(null)

    useEffect(() => {
        dispatch(fetchClientOrders());
      }, [dispatch]);

   
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true,
        };
    }, []);
    
    const [columnDefs] = useState<ColDef[]>([
        // { field: 'srNumber', headerName: "Sr. Number" },
        { field: 'orderId', headerName: "Order Id"},
        { field: 'sendTime', headerName: 'Date/Time', cellRenderer: (params) => {
            const { sendTime } = params.data;
            const formattedSendTime = sendTime ? moment(sendTime).format("DD-MM-YYYY HH:mm:ss") : '-';
            return formattedSendTime;
        }},
        { field: 'clientName', headerName: 'Client Name' },
        { field: 'item', headerName: 'Item'},
        { field: 'quantity',  headerName: 'Quantity'},
        { field: 'price', headerName: 'Amount'},
        {
            field: 'id',
            cellRenderer: (params) => {
                const { sendTime } = params.data;
                if (!sendTime) {
                    return ActionButtonRenderer([
                        {
                            click: (id: number) => {
                                dispatch(openDialog({ key: EDialogType.ClientOrdersConfirmation, extras: id, title: 'Send Order' }))
                            },
                            title: "Send"
                        }
                    ])(params);
                }
                return null;
            },
            headerName: "Send"
        },
    ])

    const onGridReady = useCallback((params: GridReadyEvent) => {
        gridRef.current!.api.sizeColumnsToFit({
            defaultMinWidth: 130,
            columnLimits: [{ key: 'task', minWidth: 300 }],
        });
    }, []);

    const getRowStyle = params => {
        const evenColor = '#c7cdd5';
        const oddColor = '#d5c7c7';
        const isEvenRow = params.node.rowIndex % 2 === 0;
        const background = isEvenRow ? evenColor : oddColor;
        return { background };
    };

    return <>
        <div className="ag-theme-alpine w-full">
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                multiSortKey={'ctrl'}
                sideBar={'filters'}
                enableCellTextSelection={true}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                getRowStyle={getRowStyle}
            >
            </AgGridReact>
        </div>

       
    </>
}

ClientOrdersTable.propTypes = {

};

export default withRouter(ClientOrdersTable);