import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment, { Moment } from 'moment';

export type TSelectRole = {
    id: number
    name: string
}

export type TSelectRoleState = {
    items: TSelectRole[];
    lastUpdated: Moment | null
}

export const fetchRole = createAsyncThunk(
    'selectRole/fetch',
    async () => {
        const response = await axios.get('/organizationUser/roles');
        return response.data
    }
)

const initialState: TSelectRoleState = {
    items: [],
    lastUpdated: null
};

const selectRoleSlice = createSlice({ 
    name: 'selectRole',
    initialState,
    reducers: {},
    extraReducers: {
        [`${fetchRole.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
            state.lastUpdated = moment()
        }
    }
});

export default selectRoleSlice.reducer;
