import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

export type TFinanceCompanies = {
    id: number
    financeComapnyName: string
    email: string
    password: string
    mobile: number
}

export type TFinanceCompaniesState = {
    items: TFinanceCompanies[]
    editing: number | boolean,
}

export const createFinanceCompany = createAsyncThunk(
    'financeComapny/create',
    async (financecompany: TFinanceCompanies, {dispatch}) => {
        try {
            const response = await axios.post(`/financecompany${financecompany.id ? "/" + financecompany.id : ''}`, financecompany);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
                );
            return response.data.data
        } catch (error: any) {
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );  
        }
    }
)

export const fetchFinanceCompany = createAsyncThunk(
    'financeCompany/fetch',
    async () => {
            const response = await axios.get('/financecompany');
            return response.data
        }
)

export const deleteFinanceCompany = createAsyncThunk(
    'financeCompany/delete',

    async (data: any, thunkAPI) => {
        try {
            const { id } = data;
            const { dispatch } = thunkAPI;
            const response = await axios.delete(`/financecompany/${id}`);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
            );
            dispatch(fetchFinanceCompany())
            return response.data 
        } catch (error: any) {
            const { dispatch } = thunkAPI;
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );  
        }
     
    }
)

const initialState: TFinanceCompaniesState = {
    items: [],
    editing: false,
   
};

const financeComapniesSlice = createSlice({
    name: 'financeComapniesList',
    initialState,
    reducers: {
        startEditing: (state, action) => {
            state.editing = action.payload
        },
        stopEditing: (state) => {
            state.editing = false
        },
    },
    extraReducers: {
        [`${createFinanceCompany.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            if (index >= 0) {
                state.items[index] = action.payload
            } else {
                state.items.push(action.payload);
            }
        },
        [`${fetchFinanceCompany.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
        [`${deleteFinanceCompany.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            state.items.splice(index, 1);
        }
    }
});

export const { startEditing, stopEditing } = financeComapniesSlice.actions;
export default financeComapniesSlice.reducer;