import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { sentenceCase } from "sentence-case";

function ICFAutocomplete(props) {
    const { error, name, id, touched, value: propVal, handleBlur, items, setFieldValue, labelKey = 'name',label } = props;

    // console.log(error);

    const [value, setValue] = useState(propVal);

    return <Autocomplete
        getOptionLabel={(option: any) => option[labelKey] || ''}
        renderInput={props => (
            <TextField
                {...props}
                label={label}
                error={error && touched && error}
                variant="standard"
            />
        )}
        key={name}
        id={id || name}
        fullWidth
        value={items.find(option => option.id === value)}
        isOptionEqualToValue={(option, value) => {
            return option.id === value
        }}
        onChange={(_, value) => {
            setFieldValue(name, value ? value.id : value)
            setValue(value ? value.id : value)
        }}
        onBlur={handleBlur}
        options={items}
    />
}

export default ICFAutocomplete;