import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Root: any = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge, & > .logo-text': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-center">
      <Link to={"/"} role="button">
        {/* <img className="logo-icon w-128 h-48" src="assets/images/logo.png" alt="logo" /> */}
        <div style={{fontSize: "40px", color: "black", fontFamily: "fantasy", marginLeft: "10px"}}>ICF</div>
      </Link>
    </Root>
  );
}

export default Logo;
