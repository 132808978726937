import withRouter from "@fuse/core/withRouter";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useRef, useState, useMemo } from "react";
import { EDialogType, openDialog } from "../../../store/dialogSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchMerchants } from "../store/MerchantsSlice";
import { startEditing } from "../store/MerchantsSlice";
import { useNavigate } from "react-router-dom";
import { fetchHistory } from "../store/HistorySlice";

function MerchantsTable(props) {
  const dispatch = useAppDispatch();
  const originalRowData = useSelector((state: any) => state.merchants.items);
    const rowData = useMemo(() => {
      return originalRowData.map((item, index) => {
        return { ...item, srNumber: index + 1 };
      });
    }, [originalRowData]);
  
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMerchants());
  }, [dispatch]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: 'srNumber', headerName: 'Sr. Number' },
    { field: "name", headerName: "Merchant Name" },
    { field: "email", headerName: "Email" },
    { field: "mobile", headerName: "Mobile Number" },
    { field: "CR", headerName: "CR Number" },
    { field: "VAT", headerName: "VAT Number" },
    { field: "IBAN", headerName: "IBAN & Bank Name" },
    {
      field: "id",
      cellRenderer: ActionButtonRenderer([
        {
          click: (id) => {
            dispatch(startEditing(id));
            dispatch(
              openDialog({
                key: EDialogType.AddMerchants,
                title: "Edit Merchant",
              })
            );
          },
          title: "Edit",
        },
        {
          click: (id) => {
            dispatch(
              dispatch(
                openDialog({
                  key: EDialogType.MerchantDeleteConformation,
                  extras: id,
                  title: "Delete Merchant",
                })
              )
            );
          },
          title: "Delete",
        },
        {
          click: (id: number) => {
            const data = { merchant_id: id};
            dispatch(fetchHistory(data));
            navigate("/merchant/history");
          },
          title: "History",
        },
      ]),
      headerName: "Action",
      minWidth: 200
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  const getRowStyle = (params) => {
    const evenColor = "#c7cdd5";
    const oddColor = "#d5c7c7";
    const isEvenRow = params.node.rowIndex % 2 === 0;
    const background = isEvenRow ? evenColor : oddColor;
    return { background };
  };

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
    </>
  );
}

MerchantsTable.propTypes = {};

export default withRouter(MerchantsTable);
