import { AppBar, Dialog, DialogContent, DialogContentText, Toolbar, Typography } from "@mui/material";

type TDialogProps = {
    title?: string;
    desc?: string;
    state?: boolean;
    handleClose?: () => void;
    handleOpen?: () => void;
    handleSuccess?: () => void;
    children?: any
}

function ICFDialog(props: TDialogProps) {
    const {  state = false, desc, handleClose, children, title } = props;
    return <Dialog open={state} onClose={handleClose} fullWidth
        maxWidth="sm">
        <AppBar position="static" elevation={0}>
            <Toolbar className="flex w-full">
                <Typography variant="subtitle1" color="inherit">
                    {title}
                    {/* Add Inventory */}
                </Typography>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <DialogContentText>{desc}</DialogContentText>
            {children}
        </DialogContent>
    </Dialog>;
}

export default ICFDialog;