import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type TAction = {
    id: number
    dateTime: string
    seller: string
    buyer: string
    amount: number
    quantity: number
    itemName: string
    paymentType: string
    status: string
}

export type TActionState = {
    items: TAction[]
}

export const fetchAction = createAsyncThunk(
    'action/fetch',
    async ( data: any) => {
        const { request_id, action_name } = data;
        const response = await axios.get(`/requests/${request_id}/action/${action_name}`);
        return response.data
    }
)

const initialState: TActionState = {
    items: [],
};

const ActionSlice = createSlice({
    name: 'action',
    initialState,
    reducers: {},
    extraReducers: {
        [`${fetchAction.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
        
    }
});

export default ActionSlice.reducer;

