import { createSlice } from '@reduxjs/toolkit';

export enum ETable {
    InventoryList = "InventoryList",
    Merchants = "Merchants",
    ClientOrders = "ClientOrders",
    FinanceCompanies = "FinanceCompanies",
    Requests = "Requests",
    Action1 = "Action1",
    Action2 = "Action2",
    Action3 = "Action3",
    Action4 = "Action4",
}

type TFilter = {
    key: string,
    value: string | number
}

type TTableFilter = {
    key: ETable
    items: TFilter[]
}

type TTableFilterState = {
    items: TTableFilter[]
}

const initialState: TTableFilterState = {
    items: []
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        registerFilter: (state, action) => {
            const filterTable = state.items.find(d => d.key === action.payload.key)
            if (!filterTable) {
                state.items.push({
                    key: action.payload.key,
                    items: []
                })
            }
        },
        addFilter: (state, action) => {
            const filterTable = state.items.find(d => d.key === action.payload.key)

            if (filterTable) {
                const { items: filters = [] } = filterTable
                const { filter: payloadFilter } = action.payload

                const filter = filters.find(d => d.key === payloadFilter.key)

                if (filter) {
                    filter.value = payloadFilter.value
                } else {
                    filterTable.items.push({
                        key: payloadFilter.key,
                        value: payloadFilter.value
                    })
                }
            }
        },
        removeFilter: (state, action) => {
            const filterTable = state.items.find(d => d.key === action.payload.key)
            if (filterTable) {
                const { items: filters = [] } = filterTable
                const { filter: payloadFilter } = action.payload

                const filterIndex = filters.findIndex(d => d.key === payloadFilter.key)

                if (filterIndex >= 0) {
                    filters.splice(filterIndex, 1);
                }
            }
        },
        clearFilter: (state, action) => {
            const filterTable = state.items.find(d => d.key === action.payload.key)
            if (filterTable) {
                const { items: filters = [] } = filterTable
                filters.splice(0, filters.length);
            }
        }
    }
});

export const { addFilter, removeFilter, registerFilter, clearFilter } = filterSlice.actions
export default filterSlice.reducer;
