import ICFForm, { TICFForm } from "app/shared/components/ICFForm/ICFForm";
import { FieldType } from "app/shared/components/ICFForm/ICFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import * as Yup from "yup";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { createInventory } from "./store/InventoryListSlice";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { useState } from "react";

function AddInventoryForm() {
  const inventory =
    useSelector((state: any) =>
      state.inventoryList.items.find(
        (log) => log.id === state.inventoryList.editing
      )
    ) || {};
  const [value] = useState<Moment | null>(moment());

  const dispatch = useAppDispatch();
  const onSubmit = (w) => {
    w.id = inventory.id;
    dispatch(createInventory(w));
    dispatch(closeDialog({ key: EDialogType.AddInventory }));
  };

  const form: TICFForm = {
    onSubmit,
    fields: [
      {
        name: "itemId",
        label: "Item",
        type: FieldType.DynamicSelect,
        reduxObjectName: "selectItem",
        labelKey: "name",
        value: inventory.itemId || "",
        validation: Yup.string().required("Please select item").typeError("Required!"),
      },
      {
        name: "unit",
        label: "Unit",
        type: FieldType.StaticSelect,
        reduxObjectName: "unit",
        value: inventory.unit || "",
        validation: Yup.string().required("Please select unit").typeError("Required!"),
        options: [
          { id: "kg", name: "Kg" },
          { id: "ltr", name: "Ltr" },
          { id: "quantity", name: "Quantity" },
        ],
      },
      {
        name: "quantity",
        label: "Quantity",
        value: inventory.quantity || "",
        reduxObjectName: "quantity",
        type: FieldType.Number,
        validation: Yup.string()
          .required("Please enter quantity")
          .typeError("Required!"),
      },
      {
        name: "price",
        label: "Price/Unit",
        type: FieldType.Number,
        reduxObjectName: "price",
        value: inventory.price || "",
        validation: Yup.string()
          .required("Please enter price per unit")
          .typeError("Required!"),
      },
    ],
    resetFormOnSubmit: true,
  };

  return (
    <>
      <div>
        <ICFForm {...form}></ICFForm>
      </div>
    </>
  );
}

export default AddInventoryForm;
