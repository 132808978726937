import { Button, IconButton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import SendIcon from "@mui/icons-material/Send";

type TActionButton = {
  click: (id: number, extras?: any) => void;
  title: string;
};
function ActionButtonRenderer(actionButtons: TActionButton | TActionButton[]) {
  let actions;
  if (!Array.isArray(actionButtons)) {
    actions = [actionButtons as TActionButton];
  } else {
    actions = actionButtons as TActionButton[];
  }

  const getButtonStyles = (title, status) => {
    const allowedStatuses = ["A4", "A3", "A2", "A1"];
    const allowedTitles = ["A4", "A3", "A2", "A1"];

    for (let i = 0; i < allowedStatuses.length; i++) {
      if (status === allowedStatuses[i]) {
        for (let j = i; j < allowedTitles.length; j++) {
          if (title === allowedTitles[j]) {
            return { color: "green" };
          }
        }
        return { display : "none"}
      }
    }
  };

  return (props: ICellRendererParams) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    return (
      <>
        {actions.map((action, index) => {
          switch (action.title) {
            case "Delete":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <DeleteIcon />
                </IconButton>
              );
              break;
            case "Edit":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <EditIcon />
                </IconButton>
              );
              break;
            case "History":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <HistoryIcon />
                </IconButton>
              );
              break;
            case "Send":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <SendIcon />
                </IconButton>
              );
              break;
            default:
              return (
                    <Button
                    onClick={() => {
                      action.click(cellValue, props.data);
                    }}
                    key={index}
                    style={getButtonStyles(action.title, props.data.actionStatus)}
                    >
                  {action.title}{" "}
                  </Button>
              );
                  break;
          }
        })}
      </>
    );
  };
}

export default ActionButtonRenderer;
