import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(response => response,
            (err) => {
                return new Promise((resolve, reject) => {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // this.emit('onUnauthorizedAccess', 'Session invalidated, Please re-login');
                        // this.emit('onAutoLogout', 'Session invalidated, Please re-login');
                        this.setSession(null);
                    }
                   
                    throw err;
                });
            }
        );
    };
    
    signInWithMobileAndPassword = (mobile, password) => {
        return new Promise(async(resolve, reject) => {
            try {
            const response = await axios
            .post('/user/signin', {
                mobile,
                password,
            }) 
           
            if (response) {
                this.setSession(response.data.token);
                resolve(response.data.user);
              } 
            } catch (error) {
                reject(error) 
            }
        });
      };

    signInWithToken = () => {
        return new Promise(async (resolve, reject) => {
            // try {
            //     const response = await axios
            //         .get('/user/signin-with-token');

            //     const { user } = response.data;
            //     resolve(user);
            // } catch (error) {
            //     // this.logout();
            //     reject(new Error('Failed to login with token.'));
            // }
        });
    };

    setSession = (token: string | null) => {
        if (token) {
            localStorage.setItem('token', token);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        } else {
            localStorage.removeItem('token');
            delete axios.defaults.headers.common.Authorization;
        }
    };

    logout = () => {
        this.setSession(null);
    };

    getAccessToken = () => {
        return window.localStorage.getItem('token');
    };

    handleAuthentication = () => {
        const accessToken = this.getAccessToken();

        if (accessToken) {
            this.setSession(accessToken);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            // this.emit('onAutoLogout', 'access_token expired');
        }
    };
}

const instance = new JwtService();

export default instance;
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}

