import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useAppDispatch } from "app/withAppProviders";
import { EDialogType, openDialog } from "../../../store/dialogSlice";
import { createRequest } from "../store/RequestsSlice";

const sellRequest = {
  id: 1,
  amount: 50,
  bankRequestId: 4,
  clientEmail: "admin@icf.com",
  clientName: "admin",
  orderType: "sell",
};

const receiveRequest = {
  id: 1,
  amount: 100,
  bankRequestId: 4,
  clientEmail: "admin@icf.com",
  clientName: "admin",
  orderType: "receive",
};

function RequestsHead(props) {
  const dispatch = useAppDispatch()

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
       
        <Typography
          component={motion.span}
          className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
        >
          Requests
        </Typography>
      </div>

        <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      >
        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(openDialog({ key: EDialogType.AddRequest, title: 'Add New Request' }))
        }}
        >
          <span className="hidden sm:flex"> + Add New Request </span>
        </Button>
        <Button
          className="whitespace-nowrap ml-5"
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(createRequest(sellRequest))
        }}
        >
          <span className="hidden sm:flex"> Sell Request </span>
        </Button>
        <Button
          className="whitespace-nowrap ml-5"
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(createRequest(receiveRequest))
        }}
        >
          <span className="hidden sm:flex"> Receive Request </span>
        </Button>
        </motion.div>
    </div>
  );
}

export default RequestsHead;
