import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { Provider, useDispatch } from 'react-redux';
import AppContext from './AppContext';
import routes from './fuse-configs/routesConfig';
import store, { persistedStore } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleOAuthProvider } from '@react-oauth/google';
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

const withAppProviders = (Component: any) => (props: any = null) => {
  const WrapperComponent = () => {
    return (
      <AppContext.Provider
        value={{
          routes,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={store}>
            <PersistGate persistor={persistedStore}>
              <GoogleOAuthProvider clientId="923301387410-s8qb5do2vb34o7ubl8sfs100t63djfem.apps.googleusercontent.com">
                <StyledEngineProvider injectFirst>
                  <Component {...props} />
                </StyledEngineProvider>
              </GoogleOAuthProvider>
            </PersistGate>
          </Provider>
        </LocalizationProvider>
      </AppContext.Provider>
    )
  };

  return WrapperComponent;
};

export default withAppProviders;
