import { Icon, IconButton } from "@mui/material";
import { motion } from "framer-motion";

export enum EICFIconType {
    IconButton,
    Icon
}

export default function ICFIcon({ icon, onClick = () => { }, title = '', type = EICFIconType.IconButton }) {

    return <>
        {type === EICFIconType.IconButton &&
            <IconButton onClick={onClick} title={title} className="mx-8" size="large">
                <Icon component={motion.span}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1, transition: { delay: 0.2 } }}
                    className="text-24 md:text-32">{icon}</Icon>
            </IconButton>
        }

        {type === EICFIconType.Icon &&
            <Icon component={motion.span}
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.2 } }}
                className="fuse-list-item-icon text-16 shrink-0">{icon}</Icon>
        }
    </>

}