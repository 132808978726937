import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        minHeight: 160,
        height: 160,
        [theme.breakpoints.up('lg')]: {
            marginRight: 12,
            borderBottomRightRadius: 20,
        },
    },
    '& .FusePageSimple-toolbar': {
        minHeight: 56,
        height: 56,
        alignItems: 'flex-end',
    },
    '& .FusePageSimple-rightSidebar': {
        width: 288,
        border: 0,
        padding: '12px 0',
    },
    '& .FusePageSimple-content': {
        // maxHeight: '100%',
        // '& canvas': {
        //     maxHeight: '100%',
        // },
    },
}));

function DashboardApp(props) {
    return (
       <></>
    );
}

export default DashboardApp;
