import withRouter from "@fuse/core/withRouter";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { fetchMerchantHistory } from "./store/MerchantHistorySlice";

function MerchantHistoryTable(props) {
    const originalRowData = useSelector((state: any) => state.merchantHistory.items);
    const rowData = useMemo(() => {
      return originalRowData.map((item, index) => {
        return { ...item, srNumber: index + 1 };
      });
    }, [originalRowData]);
  
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMerchantHistory());
  }, [dispatch]);

  const gridRef = useRef<AgGridReact<any>>(null);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: "srNumber", headerName: "Sr. Number" },
    { field: "inventory.item.name", headerName: "Item Name" },
    { field: "quantity", headerName: "Quantity" },
    { field: "inventory.item.unit", headerName: "Unit" },
    { field: "price", headerName: "Amount" },
    { field: "type", headerName: "Credit/Debit" },
    {
      field: "createdAt",
      headerName: "Date",
      valueFormatter: (params) =>
        moment(params.value).format("DD-MM-YYYY HH:mm:ss"),
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 150,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  const getRowStyle = (params) => {
    const evenColor = "#c7cdd5";
    const oddColor = "#d5c7c7";
    const isEvenRow = params.node.rowIndex % 2 === 0;
    const background = isEvenRow ? evenColor : oddColor;
    return { background };
  };

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
    </>
  );
}

MerchantHistoryTable.propTypes = {};

export default withRouter(MerchantHistoryTable);
