import ICFForm, { TICFForm } from "app/shared/components/ICFForm/ICFForm";
import { FieldType } from "app/shared/components/ICFForm/ICFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import * as Yup from "yup";
import { closeDialog, EDialogType } from "../../../store/dialogSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment, { Moment } from "moment";
import { createRequest } from "../store/RequestsSlice";
function AddRequestForm() {
  const request =
    useSelector((state: any) =>
      state.requests.items.find((log) => log.id === state.requests.editing)
    ) || {};
  const [value] = useState<Moment | null>(moment());

  const dispatch = useAppDispatch();
  const onSubmit = (w) => {
    w.id = request.id;
    dispatch(createRequest(w));
    dispatch(closeDialog({ key: EDialogType.AddRequest }));
  };

  const formFields: any[] = [
    {
      name: "bankRequestId",
      label: "Bank Name",
      type: FieldType.DynamicSelect,
      reduxObjectName: "financeCompaines",
      labelKey: "name",
      value: request.bankRequestId || "",
      validation: Yup.string()
        .required("Please enter bank name")
        .typeError("Required!"),
    },
    {
      name: "clientName",
      label: "Client Name",
      type: FieldType.Text,
      reduxObjectName: "clientName",
      value: request.clientName || "",
      validation: Yup.string()
        .required("Please enter client name")
        .typeError("Required!"),
    },
    {
      name: "clientEmail",
      label: "Client Email",
      type: FieldType.Email,
      reduxObjectName: "clientEmail",
      value: request.clientEmail || "",
      validation: Yup.string()
        .required("Please enter email")
        .typeError("Required!"),
    },
    {
      name: "orderType",
      label: "Order Type",
      type: FieldType.StaticSelect,
      reduxObjectName: "orderType",
      value: request.orderType || "",
     validation: Yup.string().required('Please enter order type').typeError('Required!'),
      options: [
        { id: "receive", name: "Receive" },
        { id: "sell", name: "Sell" },
      ],
    },
    {
        name: "amount",
        label: "Amount",
        value: request.amount || '',
        reduxObjectName: "amount",
        type: FieldType.Number,
        validation: Yup.string().required('Please enter amount').typeError('Required!')
    },
  ];

  const form: TICFForm = {
    onSubmit,
    fields: formFields,
    resetFormOnSubmit: true,
  };

  return (
    <div>
      <ICFForm {...form} />
    </div>
  );
}

export default AddRequestForm;
