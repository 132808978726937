import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import { styled } from "@mui/material/styles";
import Action3Head from "./Action3Head";
import Action3Table from "./Action3Table";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: 100,
      height: 100,
    },
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
  "& .ag-root-wrapper": {
    backgroundImage: 'url(../../assets/images/backgroundImage.jpeg)',    
  },
}));

function Action3() {
  return (
    <Root header={<Action3Head />} content={<Action3Table />} innerScroll />
  );
}

export default Action3;
