import { TextField, Typography } from "@mui/material";
// import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { sentenceCase } from "sentence-case";
import ICFError from "../ICFError";

export default function FileUpload(props) {
    const {   value, handleChange, error, touched, handleBlur,   name, label, id } = props;

    const onFileChange = (file) => {
        // upload and get s3 url
        const evt = { target: { name: name, value: file.name } }
        handleChange(evt);
    }

    return <>
        <Typography variant="caption" display="block" gutterBottom>
            {label || sentenceCase(name)}
        </Typography>

        <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="text"
            fullWidth
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            hidden
        />
        <FileUploader handleChange={onFileChange} name={`fu_${name}`} types={['PDF']} />
        <ICFError>{error && touched && error}</ICFError>
    </>
}