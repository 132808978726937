import { DatePicker } from "@mui/lab";
import { Card, FormControl, InputLabel, MenuItem, Select, TextField, Typography, FormHelperText } from "@mui/material";
import {  FieldArray } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import { sentenceCase } from "sentence-case";
// import ICFIcon from "../ICFIcon";
import FileUpload from "./fields/FileUpload";
import ICFAutocomplete from "./ICFAutocomplete";
import ICFError from "./ICFError";

export enum FieldType {
    Date,
    Text,
    LongText,
    Number,
    StaticSelect,
    DynamicSelect,
    Array,
    FileUpload,
    Email,
    Password
}

export type TICFFormItem = {
    name: string
    type: FieldType
    id?: string
    label?: string
    value?: any
    handleChange?: any
    handleBlur?: any
    validation?: any
    error?: any
    touched?: boolean
    setFieldValue?: any
    inputProps?: any
    reduxObjectName?: any,
    options?: any,
    fields?: TICFFormItem[],
    labelKey?: string,
    placeholder?: string,
}

function RenderFormItem(props: TICFFormItem) {
    const { type, value, handleChange, error, touched, handleBlur, setFieldValue, name, label, id, inputProps, reduxObjectName, options, fields } = props;
    const items = useSelector((state: any) => ((state[reduxObjectName || name] || {}).items) || [])
    switch (type) {
        case FieldType.Array:
            return <>
                <Typography variant="h6">
                    {label || sentenceCase(name)}
                </Typography>
                <FieldArray
                    key={`${name}`}
                    name={name}
                    render={(arrayHelpers: any) => {
                        return (
                            <div>
                                {value.map((singleObject, index) => {
                                    return (<Card variant="outlined" className="p-4 mb-4" key={`${name}-${index}`}>
                                        <div>
                                            {fields && fields.map(field => {
                                                return <ICFFormItem
                                                    name={`${name}[${index}][${field.name}]`}
                                                    label={field.label}
                                                    placeholder={field.placeholder}
                                                    type={field.type}
                                                    key={`${name}[${index}][${field.name}]`}
                                                    value={value[index][field.name]}
                                                    handleChange={handleChange}
                                                    error={((error || [])[index] || {})[field.name]}
                                                    touched={!!touched}
                                                    handleBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    inputProps={field.inputProps}
                                                    options={field.options}
                                                    reduxObjectName={field.reduxObjectName}
                                                />
                                            })}

                                        </div>
                                    </Card>)
                                })}
                               
                            </div>
                        )
                    }}
                />
            </>
        case FieldType.Date:
            return <>
                <DatePicker
                    key={name}
                    label={label || sentenceCase(name)}
                    value={value}
                    onChange={(value) => {
                        return setFieldValue(name, moment(value).format('YYYY-MM-DD'), true);
                    }}
                    renderInput={(params) => <TextField {...params} id={id || name} variant="outlined" fullWidth margin="dense" />}
                />
                <ICFError>{error && touched && error}</ICFError>
            </>

        case FieldType.StaticSelect:
            return <>
                <FormControl
                    fullWidth 
                    variant="standard">
                    <InputLabel id="demo-simple-select-label">{label || sentenceCase(name)}</InputLabel>
                    <Select
                        key={name}
                        displayEmpty
                        id={id || name}
                        name={name}
                        fullWidth
                        classes={{ select: 'py-8' }}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={error && touched && error}
                        >
                        {options.map(item => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)}
                    </Select>
                    <ICFError >{error && touched && error}</ICFError>
                </FormControl>
            </>;
        case FieldType.DynamicSelect:
            return <>
                <FormControl margin="dense" variant="outlined" fullWidth
                >
                    <ICFAutocomplete {...props} items={items} ></ICFAutocomplete>
                    <ICFError >{error && touched && error}</ICFError>
                </FormControl>
            </>;
        case FieldType.Text:
            return <>
                <TextField
                    key={name}
                    margin="dense"
                    id={id || name}
                    label={label || sentenceCase(name)}
                    type="text"
                    // style={{width: "50%"}}
                    fullWidth
                    value={value}
                    name={name}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={error && touched && error}
                    helperText={error && touched && error}
                />
            </>
        case FieldType.LongText:
            return <>
                <TextField
                    key={name}
                    margin="dense"
                    id={id || name}
                    label={label || sentenceCase(name)}
                    type="text"
                    // style={{width: "50%"}}
                    fullWidth
                    multiline
                    maxRows={5}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={error && touched && error}
                    helperText={(error && touched && error) || (value && value.length)}
                />
            </>
        case FieldType.Number:
            return <>
                <TextField
                    key={name}
                    margin="dense"
                    name={name}
                    id={id || name}
                    label={label || sentenceCase(name)}
                    type="number"
                    value={value}
                    fullWidth
                    // style={{width: "50%"}}
                    variant="outlined"
                    onChange={handleChange}
                    inputProps={inputProps}
                    onBlur={handleBlur}
                    error={error && touched && error}
                    helperText={error && touched && error}
                />
            </>
        case FieldType.FileUpload:
            return <>
                <FileUpload {...props} />
            </>
        case FieldType.Email:
            return <>
                <TextField
                    key={name}
                    margin="dense"
                    id={id || name}
                    label={label || sentenceCase(name)}
                    type="Email"
                    // style={{width: "50%"}}
                    fullWidth
                    value={value}
                    name={name}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={error && touched && error}
                    helperText={error && touched && error}
                />
            </>
        case FieldType.Password:
            return <>
                <TextField
                    key={name}
                    margin="dense"
                    id={id || name}
                    label={label || sentenceCase(name)}
                    type="password"
                    // style={{width: "50%"}}
                    fullWidth
                    value={value}
                    name={name}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={error && touched && error}
                    helperText={error && touched && error}
                />
            </>
        default:
            return <ICFError>Unknown field</ICFError>;
    }
}

function ICFFormItem(props: TICFFormItem) {
    return <>
        <RenderFormItem {...props}></RenderFormItem>
    </>
}

export default ICFFormItem;