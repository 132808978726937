import { useAppDispatch } from "app/withAppProviders";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { sendButton } from "./store/ClientOrdersSlice";

function ClientOrdersConfirmationForm(props: any) {
  const dispatch = useAppDispatch();
  const onCancel = () => {
    dispatch(closeDialog({ key: EDialogType.ClientOrdersConfirmation }));
  };

  const onSend = () => {
    dispatch(sendButton(props.extras));
    dispatch(closeDialog({ key: EDialogType.ClientOrdersConfirmation }));
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography className="text-24 font-semibold">
            Are you sure you want to send?
          </Typography>
        </div>
        <Stack
          direction="row"
          spacing={2}
          style={{ marginTop: "30px", marginLeft: "40%", width: "16%" }}
        >
          <Button type="submit" variant="contained" onClick={onSend}>
            Send
          </Button>
          <Button type="submit" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </div>
    </>
  );
}

export default ClientOrdersConfirmationForm;
