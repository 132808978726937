import withRouter from "@fuse/core/withRouter";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

function Action4Table(props) {
  const originalRowData = useSelector((state: any) => state.action.items);
  const rowData = useMemo(() => {
    return originalRowData.map((item, index) => {
      return { ...item, srNumber: index + 1 };
    });
  }, [originalRowData]);

  const gridRef = useRef<AgGridReact<any>>(null);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: "srNumber", headerName: "Sr. Number", minWidth: 10, maxWidth: 100 },
    {
      field: "transaction.transactionHistory.createdAt",
      headerName: "Date/Time",
      valueFormatter: (params) =>
        moment(params.value).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      headerName: "Seller",
      valueGetter: (params) => {
        const sellerName = params.data?.transaction?.transactionHistory?.seller?.name;
        return sellerName ? sellerName : "-";
      },
    },
    {
      headerName: "Buyer",
      valueGetter: (params) => {
        const buyerName = params.data?.transaction?.transactionHistory?.buyer?.name;
        return buyerName ? buyerName : "-";
      },
    },
    {
      headerName: "Amount",
      valueGetter: (params) => {
        const amount = params.data?.transaction?.transactionHistory.price;
        return amount ? amount : "-";
      },
      minWidth: 15, maxWidth: 100 
    },
    {
      // field: "transaction.transactionHistory.quantity",
      headerName: "Quantity",
      valueGetter: (params) => {
        const quantity = params.data?.transaction?.transactionHistory.quantity;
        return quantity ? quantity : "-";
      },
      minWidth: 15, maxWidth: 100
    },
    {
      headerName: "Item Name",
      valueGetter: (params) => {
        const itemName = params.data?.transaction?.transactionHistory?.inventory?.item?.name;
        return itemName ? itemName : "-";
      },
      minWidth: 15, maxWidth: 120
    },
    {
      headerName: "Payment Type ",
      valueGetter: (params) => {
        const paymentStatus = params.data?.transaction?.transactionHistory.paymentStatus;
        return paymentStatus ? paymentStatus : "-";
      },
      minWidth: 15, maxWidth: 120
    },
    { field: "transaction.transactionHistory.action", headerName: "Actions", minWidth: 400 },
    {
      headerName: "Status",
      valueGetter: (params) => {
        const action = params.data?.transaction?.transactionHistory.status;
        return action ? action : "-";
      },
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 150,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  const getRowStyle = (params) => {
    const evenColor = "#c7cdd5";
    const oddColor = "#d5c7c7";
    const isEvenRow = params.node.rowIndex % 2 === 0;
    const background = isEvenRow ? evenColor : oddColor;
    return { background };
  };

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
    </>
  );
}

Action4Table.propTypes = {};

export default withRouter(Action4Table);
