import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

export type TMerchants = {
    id: number
    email: string
    password: string
    mobile: number
    name: string
    CR: string
    VAT: string
    IBAN: string
}

export type TMerchantsState = {
    items: TMerchants[]
    editing: number | boolean,
}

export const createMerchants = createAsyncThunk(
    'merchant/create',
    async (merchant: TMerchants, {dispatch}) => {
        try {
            const response = await axios.post(`/merchant${merchant.id ? "/" + merchant.id : ''}`, merchant);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
                );
                return response.data.data;
        } catch (error: any) {
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );
        }
    }
)

export const fetchMerchants = createAsyncThunk(
    'merchant/fetch',
    async () => {
            const response = await axios.get('/merchant');
            return response.data
    }
)

export const deleteMerchants = createAsyncThunk(
    'merchants/delete',
    async (data: any, thunkAPI) => {
        try {
            const { id } = data;
            const {dispatch} = thunkAPI
            const response = await axios.delete(`/merchant/${id}`);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
            );
            dispatch(fetchMerchants())
            return response.data 
        } catch (error: any) {
            const {dispatch} = thunkAPI
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              ); 
        }
        
    }
)

const initialState: TMerchantsState = {
    items: [],
    editing: false,
};

const merchantsSlice = createSlice({
    name: 'merchantsList',
    initialState,
    reducers: {
        startEditing: (state, action) => {
            state.editing = action.payload
        },
        stopEditing: (state) => {
            state.editing = false
        },
    },
    extraReducers: {
        [`${createMerchants.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            if (index >= 0) {
                state.items[index] = action.payload
            } else {
                state.items.push(action.payload);
            }
        },
        [`${fetchMerchants.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
        [`${deleteMerchants.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            state.items.splice(index, 1);
        }
    }
});

export const { startEditing, stopEditing } = merchantsSlice.actions;
export default merchantsSlice.reducer;
