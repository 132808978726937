import { RouteConfig } from "app/shared/types";
import InventoryLists from "./InventoryList";

const InventoryListsRouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'inventoryList',
            element: <InventoryLists />,
            permissions: ["inventory_create", "inventory_read", "inventory_delete", "inventory_update"]
        },
    ],
};

export default InventoryListsRouteConfig;