import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

export type TInventory = {
    id: number,
    item: string,
    quantity: number,
    unit: string,
    price: number,
}

export type TInventoryState = {
    items: TInventory[]
    editing: number | boolean,
}

export const createInventory = createAsyncThunk(
    'inventory/create',
    async (inventory: TInventory, { dispatch }) => {
        try {
            const response = await axios.post(`/inventory${inventory.id ? "/" + inventory.id : ''}`, inventory);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
                );
            return response.data.data
        } catch (error: any) {
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );   
        }
    }
)

export const fetchInventory = createAsyncThunk(
    'inventory/fetch',
    async () => {
            const response = await axios.get('/inventory');
            return response.data
        } 
)

export const deleteInventoryList = createAsyncThunk(
    'inventory/delete',
    async (data: any, thunkAPI) => {
        try {
        const { id } = data;
        const { dispatch } = thunkAPI;
        const response = await axios.delete(`/inventory/${id}`);
        dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
            );
        dispatch(fetchInventory())
        return response.data  
        } catch (error: any) {
            const { dispatch } = thunkAPI;
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );   
        }
    }
)

const initialState: TInventoryState = {
    items: [],
    editing: false,
};

const inventorySlice = createSlice({
    name: 'inventoryList',
    initialState,
    reducers: {
        startEditing: (state, action) => {
            state.editing = action.payload
        },
        stopEditing: (state) => {
            state.editing = false
        },
    },
    extraReducers: {
        [`${createInventory.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            if (index >= 0) {
                state.items[index] = action.payload
            } else {
                state.items.push(action.payload);
            }
        },
        [`${fetchInventory.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
        [`${deleteInventoryList.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            state.items.splice(index, 1);
        }
    }
});

export const { startEditing, stopEditing } = inventorySlice.actions;
export default inventorySlice.reducer;
