import withRouter from "@fuse/core/withRouter";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useRef, useState, useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchRequests } from "../store/RequestsSlice";
import { useNavigate } from "react-router-dom";
import { fetchAction } from "../store/ActionSlice";
import moment from 'moment';

function RequestsTable(props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const originalRowData = useSelector((state: any) => state.requests.items);
  const rowData = useMemo(() => {
    return originalRowData.map((item, index) => {
      let orderId;

      if (index % 2 === 0) {
        orderId = "RAJHI-001";
      } else {
        orderId = "SNB-002";
      }
  
      return { ...item, orderId: orderId };
    });
  }, [originalRowData]);
  const gridRef = useRef<AgGridReact<any>>(null);

  const [rowDatas, setRowDatas] = useState([]);

  useEffect(() => {
    dispatch(fetchRequests());
  }, [dispatch]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    // { field: 'id', headerName: 'Request ID', minWidth: 10, maxWidth: 120 },
    { field: 'orderId', headerName: "Order Id" },
    { field: "createdAt", headerName: "Date/Time", minWidth: 30, maxWidth: 210,
      valueFormatter: (params) => moment(params.value).format('DD-MM-YYYY HH:mm:ss'),
    },
    { field: "bankRequestId", headerName: "Bank ID", width: 1 },
    { field: "clientName", headerName: "Client Name", width: 5 },
    { field: "bankData.name", headerName: "Bank Name", width: 1 },
    { field: "amount", headerName: "Amount", width: 1 },
    { field: "orderType", headerName: "Order Type", width: 1 },
    {
      field: "id",
      cellRenderer: ActionButtonRenderer([
        {
          click: async (id: number, rowData) => {            
            const data = { request_id: id, action_name: "A1" };
            const actionResult = await dispatch(fetchAction(data));
            const actionData = actionResult.payload;        
            setRowDatas(actionData);
            navigate(`/request/actionOne`, { state: { rowData } });
          },
          title: "A1",
        },
        {
          click: async (id: number, rowData) => {
            const data = { request_id: id, action_name: "A2" };
            const actionResult = await dispatch(fetchAction(data));
            const actionData = actionResult.payload;        
            setRowDatas(actionData);
            navigate("/request/actionTwo", { state: { rowData } });
          },
          title: "A2",
        },
        {
          click: async (id: number, rowData) => {
            const data = { request_id: id, action_name: "A3" };
            const actionResult = await dispatch(fetchAction(data));
            const actionData = actionResult.payload;        
            setRowDatas(actionData);
            navigate("/request/actionThree", { state: { rowData } });
          },
          title: "A3",
        },
        {
          click: (id: number) => {
            const data = { request_id: id, action_name: "A4" };
            dispatch(fetchAction(data));
            navigate("/request/actionFour");
          },
          title: "A4",
        },
      ]),
      headerName: "Action",
      minWidth: 320
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 170,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  const getRowStyle = (params) => {
    const evenColor = "#c7cdd5";
    const oddColor = "#d5c7c7";
    const isEvenRow = params.node.rowIndex % 2 === 0;
    const background = isEvenRow ? evenColor : oddColor;
    return { background };
  };

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
    </>
  );
}

RequestsTable.propTypes = {};

export default withRouter(RequestsTable);
