const fuseDark = {
  50: '#f9f9f9',
  100: '#f5f5f5',
  200: '#f1f1f1',
  300: '#ececec',
  400: '#e8e8e8',
  500: '#e4e4e4',
  600: '#e0e0e0',
  700: '#dbdbdb',
  800: '#d7d7d7',
  900: '#d3d3d3',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'dark',
};

export default fuseDark;
