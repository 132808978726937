import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

function MerchantHistoryHead(props) {
  const theme = useTheme();

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <Typography
          className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
          component={motion.span}
        >
          History
        </Typography>
      </div>
    </div>
  );
}

export default MerchantHistoryHead;
