import { RouteConfig } from "app/shared/types";
import ClientOrders from "./ClientOrders";

const ClientOrdersRouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'clientOrders',
            element: <ClientOrders />,
            permissions: ["clientOrder_read"]
        },
    ],
};

export default ClientOrdersRouteConfig;