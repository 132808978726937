import Login from './Login';

const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: [],
    routes: [
        {
            path: 'login',
            element: <Login />,
            permissions: ['non_logged_in']
        },
    ],
};

export default LoginConfig;
