import FuseUtils from '@fuse/utils';
import history from '@history';
import AppContext from 'app/AppContext';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes,  useLocation } from 'react-router-dom';

export default function FuseAuthorization(props) {
  const { children } = props;
  const appContext = useContext(AppContext);
  const { routes } = appContext as any;
  const [state, setState] = useState({
    accessGranted: false
  })

  const location = useLocation();

  let userPermissions = useSelector(({ auth }: any) => auth.user.permissions) || [];
  const isLoggedIn = useSelector(({ auth }: any) => !!auth.user.id);

  useEffect(() => {
    const { pathname } = location;

    if (pathname === '/login' && isLoggedIn ) {
      setTimeout(() => {
        if (userPermissions.includes("inventory_create", "inventory_read", "inventory_delete", "inventory_update")) {
          history.push('/inventoryList');
        } else if (userPermissions.includes("merchant_create", "merchant_read", "merchant_delete", "merchant_update")) {
          history.push('/merchants');
        }
      }, 0);
    }
    
    if (pathname === '/' && isLoggedIn ) {
      setTimeout(() => {
        if (userPermissions.includes("inventory_read",)) {
          history.push('/inventoryList');
        } else if (userPermissions.includes("merchant_read")) {
          history.push('/merchants');
        }
      }, 0);
    }

    if (isLoggedIn) {
      // injecting special permissions
      userPermissions = [...userPermissions, ...['logged_in']]
    } else {
      userPermissions = [...userPermissions, ...['non_logged_in']]

    }


    const matchedRoutes = matchRoutes(routes, pathname);


    const matched: any = matchedRoutes ? matchedRoutes[0] : false;


    const accessGranted = matched ? FuseUtils.hasPermission(matched.route.permissions, userPermissions) : true;


    setState({ accessGranted });
  }, [
    location.pathname,
    isLoggedIn
  ])

  return state.accessGranted ? <>{children}</> : null;
}
