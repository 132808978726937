import moment from "moment";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';

const handleActionClick = async (event, rowData, rowIndex, props) => {
  event.preventDefault();
  const reqData  = props.location.state.rowData;
  
  let sellerName = '';
  let buyerName = '';
  let quantity = '';
  let amount = '';
  let date = '';
  let time = '';
  let itemName = '';

  for (let i = rowIndex; i >= 0; i--) {
    if (
      rowData[i]?.transaction?.transactionHistory?.action == "Purchase invoice issued from Merchant to Platform on Credit" || rowData[i]?.transaction?.transactionHistory?.action == "Purchase invoice issued from Platform to Finance Company" || rowData[i]?.transaction?.transactionHistory?.action == "Item Credited to Client Inventory") {
      
      console.log(rowData[i]);
        sellerName = rowData[i]?.transaction?.transactionHistory?.seller?.name;
      buyerName = rowData[i]?.transaction?.transactionHistory?.buyer?.name;
      quantity = rowData[i]?.transaction?.transactionHistory.quantity;
      amount = rowData[i]?.transaction?.transactionHistory.price;
      itemName = rowData[i]?.transaction?.transactionHistory?.inventory?.item?.name;
      const dateTime = moment(rowData[i]?.transaction?.transactionHistory.createdAt);
      date = dateTime.format("DD-MM-YYYY");
      time = dateTime.format("HH:mm:ss A");      
      break;
    }
  }

  const message = `New Jafra acknowledges that on the day ${date} at ${time} the following sale transaction has been executed between: ${sellerName} as seller, ${buyerName} as buyer with the following details`;
  // const arabic = `تقر جفرا الجديدة أنه في ${date.split("").reverse().join("")} في ${time.split("").reverse().join("")}، تم تنفيذ معاملة البيع التالية بين: ${sellerName.split("").reverse().join("")} كبائع، ${buyerName.split("").reverse().join("")} كمشتري مع التفاصيل التالية`;
  const arabic = `تقر ${'New Jafra'.split("").reverse().join("")} أنه في اليوم ${date.split("").reverse().join("")} في ${time.split("").reverse().join("")} تم تنفيذ معاملة البيع التالية بين: ${sellerName.split("").reverse().join("")} كبائع، ${buyerName.split("").reverse().join("")} كمشتري مع التفاصيل التالية`
  const fontBytes =await fetch('https://icf--dev.s3.amazonaws.com/arabic5.ttf').then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  const font = await pdfDoc.embedFont(fontBytes);

  let maxWidth = 20;

  const lines = [
    arabic,
    `نوع الطلب :  ${reqData.orderType.split("").reverse().join("")}`,
    `نوع السلعة: ${itemName.split("").reverse().join("")}`,
    `الكمية: ${quantity.toString().split("").reverse().join("")}`,
    `رقم التخصيص: ${reqData.orderId.split("").reverse().join("")}`,
    `المبلغ: ${amount.toString().split("").reverse().join("")}`,
    message,
    `Order type: ${reqData.orderType}`,
    `Commodity Type: ${itemName}`,
    `Quantity: ${quantity}`,
    `Allocation number: ${reqData.orderId}`,
    `Amount: ${amount}`,
  ];

  const fontSize = 12;
  const lineHeight = font.heightAtSize(fontSize) + 15;

  for (const line of lines) {
    const textWidth = font.widthOfTextAtSize(line, fontSize);
    if (textWidth > maxWidth) {
      maxWidth = textWidth;
    }
  }

  const pageWidth = maxWidth + 50; 
  const pageHeight = 500; 

  const page = pdfDoc.addPage([pageWidth, pageHeight]);
  const { width, height } = page.getSize();
  
  let textY = height - 50; 
  for (const line of lines) {
    const textWidth = font.widthOfTextAtSize(line, fontSize);
    const textX = (width - textWidth) / 2;

    const textOptions = {
      x: textX,
      y: textY,
      size: fontSize,
      font: font,
      color: rgb(0, 0, 0),
    };
    
    page.drawText(line, textOptions);
    textY -= lineHeight; 
  }

  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
  const pdfURL = URL.createObjectURL(pdfBlob);
  window.open(pdfURL, "_blank");
  URL.revokeObjectURL(pdfURL);
};

export default handleActionClick;
