import ICFForm, { TICFForm } from "app/shared/components/ICFForm/ICFForm";
import { FieldType } from "app/shared/components/ICFForm/ICFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import * as Yup from 'yup';
import { createUser } from "./store/UserManagementSlice";

function UserManagementForm() {
   
    const dispatch = useAppDispatch();

    const onSubmit = (w) => {
        dispatch(createUser(w))
    }

    const form: TICFForm = {
        onSubmit,
        fields: [
            {
                name: "userName",
                label: "User Name ",
                reduxObjectName: "userName",
                type: FieldType.Text,
                validation: Yup.string().required('Please enter user name  ').typeError('Required!')
            },
            {
                name: "mobile",
                label: " Mobile Number",
                type: FieldType.Number,
                reduxObjectName: "mobile",
                validation: Yup.string().required('Please enter mobile number  ').typeError('Required!')
            },
            {
                name: "email",
                label: "Email ID",
                reduxObjectName: "email",
                type: FieldType.Email,
                validation: Yup.string().required('Please enter email Id  ').typeError('Required!')
            },
            {
                name: "password",
                label: "Password",
                type: FieldType.Password,
                reduxObjectName: "password",
                validation: Yup.string().required('Please enter password').typeError('Required!').min(4, 'Password must have a minimum of 4 characters')
                .max(8, 'Password must have a maximum of 8 characters')
            },
            {
                name: "roleId",
                label: "Role",
                type: FieldType.DynamicSelect,
                labelKey: "slug",
                reduxObjectName: "selectRole",
                validation: Yup.string().required('Please select role').typeError('Required!'),
            }
        ],
        resetFormOnSubmit: true
    }

    return <>
        <div style={{margin: '5%', marginLeft: '20%', marginRight: '20%'}}>
    <ICFForm {...form}></ICFForm>
        </div>
    </>
}

export default UserManagementForm