import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EDialogType {
    Worklog,
    Project,
    People,
    PeopleUserDetail,
    Account,
    Feedback,
    AddInventory,
    AddMerchants,
    AddFinanceCompanies,
    ClientOrdersConfirmation,
    MerchantDeleteConformation,
    FinanceCompanyDeleteConformation,
    InventoryListDeleteConformation,
    AddRequest
}

type TDialog = {
    key: EDialogType
    open?: boolean
    title?: string
    desc?: string,
    extras?: any
}

type TDialogState = {
    items: TDialog[]
}

const initialState: TDialogState = {
    items: []
};

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        registerDialog: (state, action: PayloadAction<TDialog>) => {
            const dialog = state.items.find(d => d.key === action.payload.key)
            if (!dialog) { // register only if dialog is not registered
                state.items.push(action.payload)
            }
        },
        openDialog: (state, action) => {
            const dialog = state.items.find(d => d.key === action.payload.key)
            if (dialog) {
                dialog.open = true;
                dialog.extras = action.payload.extras
                dialog.title = action.payload.title || dialog.title
            }
        },
        closeDialog: (state, action) => {
            const dialog = state.items.find(d => d.key === action.payload.key)
            if (dialog) {
                dialog.open = false;
                dialog.extras = null;
            }
        }
    }
});

export const { registerDialog, openDialog, closeDialog } = dialogSlice.actions
export default dialogSlice.reducer;
