import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { useAppDispatch } from "app/withAppProviders";
import { stopEditing } from './store/FinanceCompainesSlice';

function FinanceCompaniesHead(props) {
  const dispatch = useAppDispatch()

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
       
        <Typography
          component={motion.span}
          className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
        >
          Finance Companies
        </Typography>
      </div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
          >
          
            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="secondary"
              onClick={() => {
                dispatch(openDialog({ key: EDialogType.AddFinanceCompanies, title: 'Add Finance Company' }))
                dispatch(stopEditing())
              }}
            >
              <span className="hidden sm:flex"> + Add New Finance Company </span>
            </Button>
          </motion.div>
    </div>
  );
}

export default FinanceCompaniesHead;
