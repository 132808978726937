import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

export type TRequests = {
    // srNumber: number
    id: number
    // createdAt: string
    bankRequestId: number
    clientName: string
    // bankName: string
    // itemName: string
    amount: number
    orderType: string
}

export type TRequestsState = {
    items: TRequests[]
}

export const fetchRequests = createAsyncThunk(
    'requests/fetch',
    async () => {
        const response = await axios.get('/requests');
        return response.data
    }
)

export const createRequest = createAsyncThunk(
    'request/create',
    async (request: TRequests, {dispatch}) => {
        try {
            const response = await axios.post('/requests', request);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
            );
                dispatch(fetchRequests())
                return response.data.data;
        } catch (error: any) {
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );
        }
    }
)

const initialState: TRequestsState = {
    items: [],
};

const RequestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        
    },
    extraReducers: {
        [`${fetchRequests.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
        [`${createRequest.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            if (index >= 0) {
                state.items[index] = action.payload
            } else {
                state.items.push(action.payload);
            }
        },
    }
});

export default RequestsSlice.reducer;

