import { RouteConfig } from "app/shared/types";
import UserManagement from "./UserManagement";

const UserManagementRouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'userManagement',
            element: <UserManagement />,
            permissions: ["user_create", "user_read", "user_delete", "user_update"]
        },
    ],
};

export default UserManagementRouteConfig;