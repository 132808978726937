import { Navigate } from "react-router-dom";
import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import Error404Page from "app/main/404/Error404Page";
import DashboardRouteConfig from "app/main/dashboard/DashboardRouteConfig";
import LoginConfig from "app/login/LoginConfig";
import { Route } from "app/shared/types";
import InventoryListsRouteConfig from "app/main/apps/merchants/inventoryList/InventoryListRouteConfig";
import UserManagementRouteConfig from "app/main/apps/merchants/userManagement/UserManagementRouteConfig";
import ClientOrdersRouteConfig from "app/main/apps/merchants/clientOrders/ClientOrdersRouteConfig";
import MerchantsRouteConfig from "app/main/apps/superAdmin/merchants/MerchantsRouteConfig";
import FinanceCompaniesRouteConfig from "app/main/apps/superAdmin/financeCompanies/FinanceCompaniesRouteConfig";
import RequestsRouteConfig from "app/main/apps/superAdmin/requests/RequestsRouteConfig";
import HistoryRouteConfig from "app/main/apps/merchants/merchantHistory/MerchantHistoryRouteConfig";

const routeConfigs = [
  DashboardRouteConfig,
  LoginConfig,
  InventoryListsRouteConfig,
  UserManagementRouteConfig,
  ClientOrdersRouteConfig,
  MerchantsRouteConfig,
  FinanceCompaniesRouteConfig,
  RequestsRouteConfig,
  HistoryRouteConfig,
];

const routes: Route[] = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),

  {
    path: "/",
    element: <Navigate to="login" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
