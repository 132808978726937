import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import { styled } from '@mui/material/styles';
import FinanceCompaniesHead from './FinanceCompaniesHead';
import FinanceCompaniesTable from './FinanceCompaniesTable';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 100,
      height: 100,
    },
  },
  '& .FusePageSimple-content': {
    display: 'flex',
  },
  '& .FusePageSimple-contentCard': {
    overflow: 'hidden',
  },
  "& .ag-root-wrapper": {
    backgroundImage: 'url(../../assets/images/backgroundImage.jpeg)',
    
  },
}));

function FinanceCompanies() {
  return <Root header={<FinanceCompaniesHead />}
     content={<FinanceCompaniesTable />} innerScroll
  />;
}

export default FinanceCompanies

