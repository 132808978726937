const skyBlue = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  DEFAULT: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#919191',
  A100: '#D5D5D5',
  A200: '#AAAAAA',
  A400: '#303030',
  A700: '#616161',
  contrastDefaultColor: 'dark',
};

export default skyBlue;
