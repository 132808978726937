import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { darken, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import LoginWithJwt from './LoginWithJwt';


const Root = styled('div')(({ theme }) => ({
  background: 
    `linear-gradient(to right, ${theme.palette.primary.dark} 0%, 
      ${darken(
        theme.palette.primary.dark,
        0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,

    
  '& .Login-rightSection': {
    borderRadius: '50px' 
    },

  '& .Login-leftSection': {
    borderRadius: '50px' ,
      background:
        `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
            theme.palette.primary.dark,
            0.5
        )} 100%)`,
        color: theme.palette.primary.contrastText,
  },
    
  
}));

function Login() {
  
    return (
      <Root
      className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24 " 
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          className="flex w-full max-w-400 md:max-w-5xl justify-center"
          style={{ height: "100%", width: "100%" }}
        >
          <Card
            className="Login-rightSection flex flex-col w-full max-w-md items-center justify-center shadow-0"
            square
          >
            <CardContent className="flex flex-col items-center justify-center w-full">
            <Typography variant="h3" color="inherit" className="font-semibold leading-tight mb-48">
                  Welcome to NewJfra
                </Typography>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.2 } }}
              >
                <div className="flex items-center mb-48">
                  <div>
                    <Typography className="text-32 font-semibold logo-text" color="inherit">
                      Sign In
                    </Typography>
                    
                  </div>
                </div>
              </motion.div>  
            <LoginWithJwt />
            </CardContent>
          </Card>
        </motion.div>
      </Root>
    );
}

export default Login;