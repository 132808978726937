import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type TMerchantHistory = {
    id: number
    itemName: string
    quantity: number
    unit: string
    amount: number
    creditDebit: string
    date: string
}

export type TMerchantHistoryState = {
    items: TMerchantHistory[]
}

export const fetchMerchantHistory = createAsyncThunk(
    'merchantHistory/fetch',
    async () => {
        const response = await axios.get('merchant/history');
        return response.data
    }
)

const initialState: TMerchantHistoryState = {
    items: [],
};

const MerchantHistorySlice = createSlice({
    name: 'merchantHistory',
    initialState,
    reducers: {},
    extraReducers: {
        [`${fetchMerchantHistory.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
    }
});

export default MerchantHistorySlice.reducer;

