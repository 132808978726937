import { useDispatch } from "react-redux";
import { submitLogin } from "./store/loginSlice";
import * as yup from 'yup';
import {  useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';



const schema = yup.object().shape({
  mobile: yup
    .string()
    .required('Please enter your mobile number'),

  password: yup
    .string()
    .required('Please enter your password')
    .min(4, 'Password must have a minimum of 4 characters')
    .max(8, 'Password must have a maximum of 8 characters')
});

const defaultValues = {
  mobile: '',
  password: '',
};


export default function LoginWithJwt() {
  const dispatch = useDispatch<any>();

  const login = useSelector((state: any) => state.auth.login);
  const { control,  formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {  errors } = formState;

  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    login.errors.forEach((error) => {
      setError(error.type, {
        type: 'manual',
        message: error.message,
      });
    });
  }, [login.errors, setError]);

  function onSubmit(model) {
    dispatch(submitLogin(model));
  }

  return (
    <>
     <div className="w-full max-w-400">
      <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="mobile"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="number"
              error={!!errors.mobile}
              helperText={errors?.mobile?.message}
              label="Mobile Number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className="text-20" color="action">
                      user
                    </Icon>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              label="Password"
              type="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              variant="outlined"
              InputProps={{
                className: 'pr-2',
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                      <Icon className="text-20" color="action">
                        {showPassword ? 'visibility' : 'visibility_off'}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // required
            />
          )}
        />
        <a href="/">Forgot password?</a>
        
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="LOG IN"
          value="legacy"
        >
          Sign In
        </Button>
      </form>
    </div>
    </>
  );
}
