import withRouter from "@fuse/core/withRouter";
import { ColDef, GridReadyEvent} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useRef, useState, useMemo } from "react";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { startEditing } from "./store/InventoryListSlice";
import { useEffect } from "react";
import { fetchInventory } from "./store/InventoryListSlice";
import { useSelector } from "react-redux"; 

function InventoryListTable(props) {
    const dispatch = useAppDispatch()
    // const rowData = useSelector((state: any) => state.inventoryList.items);
    const originalRowData = useSelector((state: any) => state.inventoryList.items);
    const rowData = useMemo(() => {
      return originalRowData.map((item, index) => {
        return { ...item, srNumber: index + 1 };
      });
    }, [originalRowData]);
    const gridRef = useRef<AgGridReact<any>>(null)

    useEffect(() => {
        dispatch(fetchInventory());
      }, [dispatch]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true,
        };
    }, []);

    const [columnDefs] = useState<ColDef[]>([
        { field: 'srNumber', headerName: 'Sr. Number'},
        { field: 'item.name', headerName: 'Name of the Item'},
        { field: 'unit', headerName: 'Select Unit'},
        { field: 'quantity',  headerName: 'Quantity'},
        { field: 'price', headerName: 'Price/Unit' },
        {
            field: 'id',
            cellRenderer: ActionButtonRenderer(
                [
                    {
                        click: (id) => {
                                dispatch(startEditing(id))
                            dispatch(openDialog({ key: EDialogType.AddInventory, title: 'Edit Inventory' }))
                        },
                        title: "Edit",
                    },
                    {
                        click: (id) => {
                                dispatch(openDialog({ key: EDialogType.InventoryListDeleteConformation, extras: id, title: 'Delete Inventory'}))
                        },
                        title: "Delete",
                    },
                    {
                        click: () => {
                        },
                        title: "History",
                    },
                   
                    ], 
            ),
            headerName: "Action",
        },
        
    ])
 
    const onGridReady = useCallback((params: GridReadyEvent) => {
        gridRef.current!.api.sizeColumnsToFit({
            defaultMinWidth: 130,
            columnLimits: [{ key: 'task', minWidth: 300 }],
        });
      }, []);


    const getRowStyle = params => {
        const evenColor = '#c7cdd5';
        const oddColor = '#d5c7c7';
        const isEvenRow = params.node.rowIndex % 2 === 0;
        const background = isEvenRow ? evenColor : oddColor;
        return { background };
    };

    return <>
        <div className="ag-theme-alpine w-full">
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                multiSortKey={'ctrl'}
                sideBar={'filters'}
                enableCellTextSelection={true}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                getRowStyle={getRowStyle}
            >
            </AgGridReact>
        </div>

       
    </>
}

InventoryListTable.propTypes = {

};

export default withRouter(InventoryListTable);
