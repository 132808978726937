import { RouteConfig } from "app/shared/types";
import Requests from "./requests/Requests";
import Action1 from "./action1/Action1";
import Action2 from "./action2/Action2";
import Action3 from "./action3/Action3";
import Action4 from "./action4/Action4";

const RequestsRouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'requests',
            element: <Requests />,
            permissions: ['request_read']
        },
        {
            path: 'request/actionOne',
            element: <Action1 />,
            permissions: ['request_read']
        },
        {
            path: 'request/actionTwo',
            element: <Action2 />,
            permissions: ['request_read']
        },
        {
            path: 'request/actionThree',
            element: <Action3 />,
            permissions: ['request_read']
        },
        {
            path: 'request/actionFour',
            element: <Action4 />,
            permissions: ['request_read']
        },
    ],
};

export default RequestsRouteConfig;