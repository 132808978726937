import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import UserManagementHead from './UserManagementHead';
import UserManagementForm from './UserManagementForm';
import { margin, width } from '@mui/system';

import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 100,
      height: 100,
    },
    
  },
  '& .FusePageSimple-content': {
    display: 'flex',
  },

  '& .FusePageSimple-contentCard': {
    overflow: 'hidden',
    marginLeft: '30%',
    marginTop: '1%',
    borderRadius: '20px',
    minHeight: "20px",
  },
}));

function UserManagement() {
  return <Root header={<UserManagementHead />}
     content={<UserManagementForm />} 
  />;
}

export default UserManagement
