import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type THistory = {
    id: number
    merchantName: string
    itemName: string
    quantity: number
    unit: string
    amount: number
    creditDebit: string
    date: string
}

export type THistoryState = {
    items: THistory[]
}

export const fetchHistory = createAsyncThunk(
    'history/fetch',
    async (data: any) => {
        const { merchant_id} = data;
        const response = await axios.get(`merchant/history/${merchant_id}`);
        return response.data
    }
)

const initialState: THistoryState = {
    items: [],
};

const HistorySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {},
    extraReducers: {
        [`${fetchHistory.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
        },
        
    }
});

export default HistorySlice.reducer;

