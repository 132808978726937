import { Formik } from "formik";
import ICFFormItem, { TICFFormItem } from "./ICFFormItem";
import * as Yup from 'yup';
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack';

export type TICFForm = {
    fields: TICFFormItem[],
    onSubmit: (values) => void,
    resetFormOnSubmit?: boolean
}

function buildValidationSchema(fields) {
    const rtn = fields.reduce((pv, cv) => {
        if (cv.validation)
            pv[cv.name] = cv.validation

        return pv;
    }, {});
    return Yup.object().shape(rtn)
}

function buildInitValues(fields) {
    const rtn = fields.reduce((pv, cv) => {
        pv[cv.name] = cv.value
        return pv;
    }, {});
    return rtn;
}

function ICFForm(props: TICFForm) {
    const { fields, onSubmit, resetFormOnSubmit = false } = props;

    return (
        <>
            <Formik
                initialValues={buildInitValues(fields)}
                validationSchema={buildValidationSchema(fields)}
                onSubmit={async (values, actions) => {
                    await onSubmit(values)
                    if (resetFormOnSubmit) {
                        actions.resetForm();
                    }
                    actions.setSubmitting(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    // isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            {fields && fields.map(field => <ICFFormItem
                                name={field.name}
                                label={field.label}
                                type={field.type}
                                key={field.name}
                                value={values[field.name]}
                                handleChange={handleChange}
                                error={errors[field.name]}
                                touched={!!touched[field.name]}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                inputProps={field.inputProps}
                                labelKey={field.labelKey}
                                options={field.options}
                                reduxObjectName={field.reduxObjectName}
                                fields={field.fields}
                                />)}

                            <Stack spacing={2}   style={{marginTop: "30px", marginLeft: "40%", width: '16%'}}>
                                <Button type="submit"  variant="contained">
                                    Save
                                </Button>
                            </Stack>
                        </form>
                    )
                }}
            </Formik>
        </>
    )
}

export default ICFForm;