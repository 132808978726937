import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import { styled } from "@mui/material/styles";
import MerchantsHead from "./MerchantsHead";
import MerchantsTable from "./MerchantsTable";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: 100,
      height: 100,
    },
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
  "& .ag-root-wrapper": {
    backgroundImage: 'url(../../assets/images/backgroundImage.jpeg)',
  },
}));

function Merchants() {
  return (
    <Root header={<MerchantsHead />} content={<MerchantsTable />} innerScroll />
  );
}

export default Merchants;
