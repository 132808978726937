const navigationConfig = [
  {
    id: 'merchants',
    title: 'Merchants',
    type: 'item',
    url: 'merchants',
    permissions: ["merchant_create", "merchant_read", "merchant_delete", "merchant_update"]
  },
    {
    id: 'financeCompanies',
    title: 'Finance Companies ',
    type: 'item',
    url: 'financeCompanies',
    permissions: ["financeCompany_create", "financeCompany_read", "financeCompany_delete", "financeCompany_update"]
  },
  // {
  //   id: 'reports',
  //   title: 'reports',
  //   type: 'item',
  //   url: '',
  //   permissions: ['superAdmin']
  // },
  {
    id: 'requests',
    title: 'Requests',
    type: 'item',
    url: 'requests',
    permissions: ['request_read']
  },
  {
    id: 'inventoryList',
    title: 'Inventory',
    type: 'item',
    url: 'inventoryList',
    permissions: ["inventory_create", "inventory_read", "inventory_delete", "inventory_update"]
  },
  {
    id: 'userManagement',
    title: 'User Management',
    type: 'item',
    url: 'userManagement',
    permissions: ["user_create", "user_read", "user_delete", "user_update"]
  },
  {
    id: 'clientOrders',
    title: 'Client Orders',
    type: 'item',
    url: 'clientOrders',
    permissions: ["clientOrder_read"]
  },
  {
    id: 'history',
    title: 'Credit/Debit',
    type: 'item',
    url: 'history',
    permissions: ["creditDebitHistory_read"]
  },
];

export default navigationConfig;
