import Typography from '@mui/material/Typography';


function RequestsToolbar(props) {


    return (
      <>
    <div className="flex  my-2 mb-5" style={{paddingLeft: "15%", margin: "5%"}}>
        <Typography className="md:text-17 mx-12">
        This actions button indicated below process of order
        </Typography> 
    </div>
    <div className="flex flex-1 w-full flex-col justify-center mb-5">
        <div className="flex  my-2">
        <Typography className=" md:text-17 mx-12 ">
          A1 Order From Platform To Merchant Order 
        </Typography> 
          </div>
          <div className="flex  my-2">
        <Typography className=" md:text-17 mx-12 ">
          A2 Order From Finance company to platform
        </Typography> 
        </div>
        <div className="flex  my-2">
        <Typography className=" md:text-17 mx-12 ">
        </Typography> 
        </div>
        <div className="flex  my-2">
        <Typography className=" md:text-17 mx-12 ">
          A3 Order From Client to Finance company 
        </Typography> 
        </div>
        <div className="flex  my-2 mb-5">
        <Typography
        className=" md:text-17 mx-12 ">
          A4 Order From Merchant 2 to Client 
        </Typography> 
      </div>
    </div>
    </>
  );
}

export default RequestsToolbar;
