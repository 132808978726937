import { RouteConfig } from "app/shared/types";
import History from "./MerchantHistory";

const HistoryRouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'history',
            element: <History />,
            permissions: ["creditDebitHistory_read"]
        },
    ],
};

export default HistoryRouteConfig;