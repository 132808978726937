import ICFForm, { TICFForm } from "app/shared/components/ICFForm/ICFForm";
import { FieldType } from "app/shared/components/ICFForm/ICFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import * as Yup from 'yup';
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { useSelector } from "react-redux";
import { createFinanceCompany } from "./store/FinanceCompainesSlice";
import { useState } from "react";
import moment, { Moment } from "moment";

function AddFinanceCompanyForm() {
    const financeCompany = useSelector((state: any) => state.financeCompaines.items.find(log => log.id === state.financeCompaines.editing)) || {}
    const [value] = useState<Moment | null>(moment());

    const dispatch = useAppDispatch();
    const onSubmit = (w) => {
        w.mobile = w.mobile.toString();
        w.id = financeCompany.id;
        dispatch(createFinanceCompany(w))
        dispatch(closeDialog({ key: EDialogType.AddFinanceCompanies }))
    }

    const isEditMode = Boolean(financeCompany.id);

    const formFields: any[] = [
        {
            name: "name",
            label: "Finance Comapny Name",
            type: FieldType.Text,
            reduxObjectName: "name",
            value: financeCompany.name || '',
            validation: Yup.string().required('Please enter name').typeError('Required!')
        },
        {
            name: "email",
            label: "Email",
            type: FieldType.Email,
            reduxObjectName: "email",
            value: financeCompany.email || '',
            validation: Yup.string().required('Please enter email').typeError('Required!')
        },
        {
            name: "mobile",
            label: "Mobile",
            type: FieldType.Number,
            reduxObjectName: "mobile",
            value: financeCompany.mobile || '',
            validation: Yup.string().required('Please enter mobile number').typeError('Required!')
        },
    ];
    if (!isEditMode) {
        formFields.push({
            name: "password",
            label: "Password",
            type: FieldType.Password,
            reduxObjectName: "password",
            value: financeCompany.password || '',
            validation: Yup.string().required('Please enter password').typeError('Required!').min(4, 'Password must have a minimum of 4 characters')
            .max(8, 'Password must have a maximum of 8 characters')
        });
    }
    const form: TICFForm = {
        onSubmit,
        fields: formFields,
        resetFormOnSubmit: true
    };

    

    return <>
        <div >
    <ICFForm {...form}></ICFForm>
        </div>
    </>
}

export default AddFinanceCompanyForm