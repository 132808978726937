import { RouteConfig } from "app/shared/types";
import Merchants from "./merchants/Merchants";
import History from "./history/History";

const MerchantsRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "merchants",
      element: <Merchants />,
      permissions: [
        "merchant_create",
        "merchant_read",
        "merchant_delete",
        "merchant_update",
      ],
    },
    {
      path: "merchant/history",
      element: <History />,
      permissions: ["merchantCreditDebitHistory_read"],
    },
  ],
};

export default MerchantsRouteConfig;
