import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

const data = [
    {
        id: 1,
        sendTime: "2023-01-01T12:00:00",
        orderId: "RAJHI-001",
        clientName: "Test",
        item: "Rice",
        quantity: 10,
        price: 250
    }
]

export type TClientOrders = {
    id: number
    sendTime: string
    clientName: string
    item: string
    quantity: number
    price: number
}

export type TClientOrdersState = {
    items: TClientOrders[]
   
}

export const sendButton = createAsyncThunk(
    'clientOrders/send',
    async (id: any, {dispatch}) => {
        try {
            const response = await axios.post(`/merchant/sentOrder/${id}`);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
                );
            dispatch(fetchClientOrders())
            return response.data 
        } catch (error: any) {
            dispatch(
                showMessage({
                    message: "error",
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );     
        }
        
    }
)

export const fetchClientOrders = createAsyncThunk(
    'clientOrders/fetch',
    async () => {
            //  const response = await axios.get('/merchant/clientOrders')
        return data;
    }
)

const initialState: TClientOrdersState = {
    items: [],
};

const clientOrdersSlice = createSlice({
    name: 'clientOrders',
    initialState,
    reducers: {
       
    },
    extraReducers: {
        [`${sendButton.fulfilled}`]: (state, action) => {
            // state.items = action.payload.data;
        },
        [`${fetchClientOrders.fulfilled}`]: (state, action) => {
            state.items = data;
        },
      
    }
});

export default clientOrdersSlice.reducer;
