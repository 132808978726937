import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment, { Moment } from 'moment';

export const fetchEnum = createAsyncThunk(
    'misc/enums',
    async () => {
        const response = await axios.get('/misc/enums');
        return response.data
    }
)

const initialState = {};

const enumSlice = createSlice({
    name: 'enum',
    initialState,
    reducers: {},
    extraReducers: {
        [`${fetchEnum.fulfilled}`]: (state, action) => {
            state = action.payload;
            return state
        }
    }
});

export default enumSlice.reducer;
