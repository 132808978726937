import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';

export const submitLogin = ({ mobile, password }) => async (dispatch) => {
  try {
    const user = await jwtService.signInWithMobileAndPassword(mobile, password);
    dispatch(setUserData(user));
    dispatch(
      showMessage({
        message: "Login successful",
        variant: "success",
        autoHideDuration: 5000
      })
  );
 
  } catch (error: any) {
    dispatch(
      showMessage({
        message: error.response.data.error,
        variant: "error",
        autoHideDuration: 5000
      })
    );
  }
};

const initialState = {
  success: false,
  errors: [],
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
