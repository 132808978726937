import { useEffect } from "react";
import InventoryListAddDialog from "./main/apps/merchants/inventoryList/InventoryListAddDialog";
import ClientOrdersDialog from "./main/apps/merchants/clientOrders/ClientOrdersDialog";
import MerchantsDialog from "./main/apps/superAdmin/merchants/merchants/MerchantsAddDialog";
import AddFinanceCompaniesDialog from "./main/apps/superAdmin/financeCompanies/FinanceCompaniesAddDialog";
import { EDialogType, registerDialog } from "./main/apps/store/dialogSlice";
import { useAppDispatch } from "./withAppProviders";
import { useSelector } from "react-redux";
import { fetchItem } from "./main/apps/store/selectItemSlice";
import { fetchRole } from "./main/apps/store/selectRoleSlice";
import MerchantsDeleteDialog from "./main/apps/superAdmin/merchants/merchants/MerchantsDeleteDialog";
import FinanceCompaniesDeleteDialog from "./main/apps/superAdmin/financeCompanies/FinanceCompaniesDeleteDialog";
import InventoryListDeleteDialog from "./main/apps/merchants/inventoryList/InventoryListDeleteDialog";
import RequestAddDialog from "./main/apps/superAdmin/requests/requests/RequestAddDialog";
import { fetchFinanceCompany } from "./main/apps/superAdmin/financeCompanies/store/FinanceCompainesSlice";

function Init({ children }) {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(({ auth }: any) => !!auth.user.id);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchItem());
      dispatch(fetchRole());
      dispatch(fetchFinanceCompany())

      dispatch(
        registerDialog({
          key: EDialogType.AddInventory,
          open: false,
          title: "Inventory List",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.AddMerchants,
          open: false,
          title: "Merchants",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.AddFinanceCompanies,
          open: false,
          title: "Add Finance Company",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.ClientOrdersConfirmation,
          open: false,
          title: "",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.MerchantDeleteConformation,
          open: false,
          title: "",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.FinanceCompanyDeleteConformation,
          open: false,
          title: "",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.InventoryListDeleteConformation,
          open: false,
          title: "",
          desc: "",
        })
      );
      dispatch(
        registerDialog({
          key: EDialogType.AddRequest,
          open: false,
          title: "",
          desc: "",
        })
      );
    }
  }, [dispatch, isLoggedIn]);

  return (
    <>
      {children}
      <InventoryListAddDialog />
      <MerchantsDialog />
      <AddFinanceCompaniesDialog />
      <ClientOrdersDialog />
      <MerchantsDeleteDialog />
      <FinanceCompaniesDeleteDialog />
      <InventoryListDeleteDialog />
      <RequestAddDialog />
    </>
  );
}

export default Init;
