import ICFForm, { TICFForm } from "app/shared/components/ICFForm/ICFForm";
import { FieldType } from "app/shared/components/ICFForm/ICFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import * as Yup from "yup";
import { closeDialog, EDialogType } from "../../../store/dialogSlice";
import { createMerchants } from "../store/MerchantsSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment, { Moment } from "moment";

function AddMerchantForm() {
  const merchant =
    useSelector((state: any) =>
      state.merchants.items.find((log) => log.id === state.merchants.editing)
    ) || {};
  const [value] = useState<Moment | null>(moment());

  const dispatch = useAppDispatch();
  const onSubmit = (w) => {
    w.mobile = w.mobile.toString();
    w.id = merchant.id;
    dispatch(createMerchants(w));
    dispatch(closeDialog({ key: EDialogType.AddMerchants }));
  };

  const isEditMode = Boolean(merchant.id);

  const formFields: any[] = [
    {
      name: "name",
      label: "Merchant Name",
      type: FieldType.Text,
      reduxObjectName: "name",
      value: merchant.name || "",
      validation: Yup.string()
        .required("Please enter name")
        .typeError("Required!"),
    },
    {
      name: "email",
      label: "Email",
      type: FieldType.Email,
      reduxObjectName: "email",
      value: merchant.email || "",
      validation: Yup.string()
        .required("Please enter email")
        .typeError("Required!"),
    },
    {
      name: "mobile",
      label: "Mobile Number",
      type: FieldType.Number,
      reduxObjectName: "mobile",
      value: merchant.mobile || "",
      validation: Yup.string()
        .required("Please enter mobile number")
        .typeError("Required!"),
    },
    {
      name: "CR",
      label: "CR Number",
      value: merchant.CR || "",
      reduxObjectName: "CR",
      type: FieldType.Text,
      validation: Yup.string()
        .required("Please enter CR number")
        .typeError("Required!"),
    },
    {
      name: "VAT",
      label: "VAT Number",
      type: FieldType.Text,
      reduxObjectName: "VAT",
      value: merchant.VAT || "",
      validation: Yup.string()
        .required("Please enter VAT number")
        .typeError("Required!"),
    },
    {
      name: "IBAN",
      label: "IBAN & Bank Name",
      value: merchant.IBAN || "",
      reduxObjectName: "IBAN",
      type: FieldType.Text,
      validation: Yup.string()
        .required("Please enter bank name")
        .typeError("Required!"),
    },
  ];

  if (!isEditMode) {
    formFields.push({
      name: "password",
      label: "Password",
      type: FieldType.Password,
      reduxObjectName: "password",
      value: merchant.password || "",
      validation: Yup.string()
        .required("Please enter password")
        .typeError("Required!")
        .min(4, "Password must have a minimum of 4 characters")
        .max(8, "Password must have a maximum of 8 characters"),
    });
  }

  const form: TICFForm = {
    onSubmit,
    fields: formFields,
    resetFormOnSubmit: true,
  };

  return (
    <div>
      <ICFForm {...form} />
    </div>
  );
}

export default AddMerchantForm;
