import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/login/store';
import dialogSlice from 'app/main/apps/store/dialogSlice';
import tableFilterSlice from 'app/main/apps/store/tableFilterSlice';
import fuse from './fuse';
import i18n from './i18nSlice';
import enumSlice from 'app/main/apps/store/enumSlice';
import InventoryListSlice from 'app/main/apps/merchants/inventoryList/store/InventoryListSlice';
import SelectItemSlice from 'app/main/apps/store/selectItemSlice';
import UserManagementSlice from 'app/main/apps/merchants/userManagement/store/UserManagementSlice';
import SelectRole from 'app/main/apps/store/selectRoleSlice';
import ClientOrdersSlice from 'app/main/apps/merchants/clientOrders/store/ClientOrdersSlice';
import MerchantsSlice from 'app/main/apps/superAdmin/merchants/store/MerchantsSlice';
import FinanceCompainesSlice from 'app/main/apps/superAdmin/financeCompanies/store/FinanceCompainesSlice';
import RequestsSlice from 'app/main/apps/superAdmin/requests/store/RequestsSlice';
import ActionSlice from 'app/main/apps/superAdmin/requests/store/ActionSlice';
import HistorySlice from 'app/main/apps/superAdmin/merchants/store/HistorySlice';
import MerchantHistorySlice from 'app/main/apps/merchants/merchantHistory/store/MerchantHistorySlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    fuse,
    i18n,
    enum: enumSlice,
    dialog: dialogSlice,
    tableFilter: tableFilterSlice,
    inventoryList: InventoryListSlice,
    selectItem: SelectItemSlice,
    userManagement: UserManagementSlice,
    selectRole: SelectRole,
    clientOrders: ClientOrdersSlice,
    merchants: MerchantsSlice,
    financeCompaines: FinanceCompainesSlice,
    requests: RequestsSlice,
    action: ActionSlice,
    history: HistorySlice,
    merchantHistory: MerchantHistorySlice,
    ...asyncReducers,
  });

  /*
  Reset the redux store when user logged out
   */
  if (action.type === 'auth/user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action as never);
};

export default createReducer;
