import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

export type TUserManagement = {
    id: number
    userName: string
    email: string
    mobile: number
    password: string
    roleId: string
}

export type TUserManagementState = {
    items: TUserManagement[]
}

export const createUser = createAsyncThunk(
    'user/create',
    async (userManagement: TUserManagement, { dispatch }) => {
        try {
            const response = await axios.post('/organizationUser', userManagement);
            dispatch(
                showMessage({ message: response.data.msg, variant: "success", autoHideDuration: 2000 }),
                );
            return response.data
        } catch (error: any) {
            dispatch(
                showMessage({
                    message: error.response.data.error,
                    variant: 'error',
                  autoHideDuration: 2000,
                })
              );  
        }
    }
)

const initialState: TUserManagementState = {
    items: [],
};

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
    },
    extraReducers: {
        [`${createUser.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            if (index >= 0) {
                state.items[index] = action.payload
            } else {
                state.items.push(action.payload);
            }
        },
        
      
    }
});

export default userManagementSlice.reducer;
