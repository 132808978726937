import { ThemeProvider } from '@mui/material/styles';
import NavbarToggleFab from 'app/fuse-layouts/shared-components/NavbarToggleFab';
import { selectNavbarTheme } from 'app/store/fuse/settingsSlice';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import NavbarStyle1 from './navbar/style-1/NavbarStyle1';

function NavbarWrapperLayout1(props) {
  const config = useSelector(({ fuse }: any) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }: any) => fuse.navbar);

  const navbarTheme = useSelector(selectNavbarTheme);

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <>
          {config.navbar.style === 'style-1' && <NavbarStyle1 />}
          {/* {config.navbar.style === 'style-2' && <NavbarStyle2 />} */}
          {/* {config.navbar.style === 'style-3' && <NavbarStyle3 />} */}
        </>
      </ThemeProvider>

      {config.navbar.display && !config.toolbar.display && !navbar.open && <NavbarToggleFab />}
    </>
  );
}

export default memo(NavbarWrapperLayout1);
