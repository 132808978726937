import { useAppDispatch } from "app/withAppProviders";
import { closeDialog, EDialogType } from "../../../store/dialogSlice";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { deleteMerchants } from "../store/MerchantsSlice";

function MerchantDeleteConfirmationForm(props: any) {
  const dispatch = useAppDispatch();
  const onCancel = (w) => {
    dispatch(closeDialog({ key: EDialogType.MerchantDeleteConformation }));
  };

  const onDelete = () => {
    dispatch(deleteMerchants({ id: +props.extras }));
    dispatch(closeDialog({ key: EDialogType.MerchantDeleteConformation }));
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography className="text-24 font-semibold">
            Are you sure you want to delete?
          </Typography>
        </div>
        <Stack
          direction="row"
          spacing={2}
          style={{ marginTop: "30px", marginLeft: "40%", width: "16%" }}
        >
          <Button type="submit" variant="contained" onClick={onDelete}>
            Yes
          </Button>
          <Button type="submit" variant="contained" onClick={onCancel}>
            No
          </Button>
        </Stack>
      </div>
    </>
  );
}

export default MerchantDeleteConfirmationForm;
